
// Oppermann custom styles
.logo-container {
    .logo {
        display: inline-block;
        background: url(../images/logo/opp-logo.gif) top left;
        width: 246px;
        height: 38px;
        margin-top: 22px;
        @include min-screen($screen__xxs) {
            width: 299px;
            height: 43px;
            margin-top: 15px;
        }
        @include min-screen($screen__m) {
            width: 205px;
            height: 33px;
            margin-top: 22px;
        }
        @include min-screen($screen__ml) {
            width: 299px;
            height: 43px;
            margin-top: 15px;
        }
        @include high-res-img {
            background-image: url('../images/logo/opp-logo@2x.gif');
        }
    }
}


.sticky-wrapper {
    .wrapper-content {
        .nav-item {
            &.item-stickylogo {
                display: none;
                @include min-screen($screen__m) {
                    display: block;
                    background: $color-blue-opp;
                    width: 0;
                    border: none;
                    overflow: hidden;
                    padding-top: 0;
                    padding-bottom: 0;
                    a {
                        background: url(../images/logo/logo-thmb-oppermann.png);
                        background-position: center center;
                        background-size: 100%;
                        display: block;
                        width: 68px;
                        height: 60px;
                        text-indent: -99999px;
                    }

                }
            }
        }
    }
}
.slide-slider-container .btn-green,
.home-page-contact .btn-green {
    background: linear-gradient($color-blue, $color-blue);
    &:hover {
        background: linear-gradient(darken($color-blue, 7%), darken($color-blue, 7%));
    }
}

.home-page-contact {
    background: $color-blue-opp;
    @include min-screen($screen__s) {
        background: $color-blue-opp url(../images/assets/bgd-home-contact_oppermann.jpg) center top no-repeat;
    }
}
.onestepcheckout-index-index .header .logo-container {
    top: 17px;
}
